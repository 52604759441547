import React from 'react';
import './Search.scss';

const Search = () => {
  return(
    <div className="Search menu-item" >
      <i className="material-icons">search</i>
    </div>
  )
};

export default Search;