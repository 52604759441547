import React from 'react';
import Logo from '../../img/small-logo.png';

const Mid = () => {
  return (
    <div className="mid">
      <div className="img-container">
        <img src={Logo} alt="logo" />
      </div>
    </div>
  )
};

export default Mid;